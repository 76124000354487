import { SearchOutlined } from "@ant-design/icons";
import "./App.css";
import { Card, Col, Divider, Input, Layout, Menu, Row } from "antd";

const { Header, Footer, Content } = Layout;

function App() {
  return (
    <Layout>
      <Header>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          items={new Array(1).fill(null).map((_, index) => {
            const key = index + 1;
            return {
              key,
              label: `Home`,
            };
          })}
        />
      </Header>
      <Content className="content">
        <Card>
          <Input placeholder="搜索" size="large" prefix={<SearchOutlined />} />
        </Card>
        <Card title="元件功能注释模块（GO/EC)" className="card_title">
          <Row gutter={16}>
            <Col className="gutter-row">
              <div>col-6</div>
            </Col>
            <Col className="gutter-row">
              <div>col-6</div>
            </Col>
            <Col className="gutter-row">
              <div>col-6</div>
            </Col>
            <Col className="gutter-row">
              <div>col-6</div>
            </Col>
            <Col className="gutter-row">
              <div>col-6</div>
            </Col>
            <Col className="gutter-row">
              <div>col-6</div>
            </Col>
          </Row>
        </Card>
        <Divider />
        <Card title="元件挖掘模块" className="card_title">
          <Row gutter={16}>
            <Col className="gutter-row">
              <div>col-6</div>
            </Col>
          </Row>
        </Card>
        <Card title="元件评估模块" className="card_title">
          <Row gutter={16}>
            <Col className="gutter-row">
              <div>col-6</div>
            </Col>
          </Row>
        </Card>
        <Card title="元件设计模块" className="card_title">
          <Row gutter={16}>
            <Col className="gutter-row">
              <div>col-6</div>
            </Col>
          </Row>
        </Card>
      </Content>
      {/* <Footer>Footer</Footer> */}
    </Layout>
  );
}

export default App;
